import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

document.addEventListener("DOMContentLoaded", function () {
  gsap.to(".version-salut", {
    scrollTrigger: {
      trigger: ".version-salut",
      start: "top 80%",
    },
    y: "-40px",
    duration: 0.5,
    delay: 0.5,
  });

  gsap.from(".version-salut", {
    scrollTrigger: {
      trigger: ".version-salut",
      start: "top 80%",
    },
    opacity: 0,
    duration: 0.5,
    delay: 0.5,
  });

  gsap.to(".version-prenom", {
    scrollTrigger: {
      trigger: ".version-prenom",
      start: "top 70%",
    },
    y: "-40px",
    duration: 0.5,
    delay: 0.6,
  });

  gsap.from(".version-prenom", {
    scrollTrigger: {
      trigger: ".version-prenom",
      start: "top 70%",
    },
    opacity: 0,
    duration: 0.5,
    delay: 0.6,
  });

  // gsap.to(".version-description", {
  //   scrollTrigger: {
  //     trigger: ".version-description",
  //     start: "top 100%",
  //     marker: true,
  //   },
  //   y: "-50px",
  //   duration: 0.3,
  //   delay: 0.3,
  // });

  // gsap.from(".version-description", {
  //   scrollTrigger: {
  //     trigger: ".version-description",
  //     start: "top 100%",
  //     marker: true,
  //   },
  //   opacity: 0,
  //   duration: 0.3,
  //   delay: 0.3,
  // });

  //  MAIN INDEX

  gsap.to(".beetle-desktop", {
    scrollTrigger: {
      trigger: ".beetle-desktop",
      start: "top 80%",
    },
    y: "-40px",
    duration: 0.3,
    delay: 0.3,
  });

  gsap.from(".beetle-desktop", {
    scrollTrigger: {
      trigger: ".beetle-desktop",
      start: "top 80%",
    },
    opacity: 0,
    duration: 0.3,
    delay: 0.3,
  });

  gsap.to(".workshop-desktop", {
    scrollTrigger: {
      trigger: ".workshop-desktop",
      start: "top 80%",
    },
    y: "-40px",
    duration: 0.3,
    delay: 0.3,
  });

  gsap.from(".workshop-desktop", {
    scrollTrigger: {
      trigger: ".workshop-desktop",
      start: "top 80%",
    },
    opacity: 0,
    duration: 0.3,
    delay: 0.3,
  });

  gsap.to(".garden-desktop", {
    scrollTrigger: {
      trigger: ".garden-desktop",
      start: "top 80%",
    },
    y: "-40px",
    duration: 0.3,
    delay: 0.3,
  });

  gsap.from(".garden-desktop", {
    scrollTrigger: {
      trigger: ".garden-desktop",
      start: "top 80%",
    },
    opacity: 0,
    duration: 0.3,
    delay: 0.3,
  });

  gsap.to(".eikonica-desktop", {
    scrollTrigger: {
      trigger: ".eikonica-desktop",
      start: "top 80%",
    },
    y: "-40px",
    duration: 0.3,
    delay: 0.3,
  });

  gsap.from(".eikonica-desktop", {
    scrollTrigger: {
      trigger: ".eikonica-desktop",
      start: "top 80%",
    },
    opacity: 0,
    duration: 0.3,
    delay: 0.3,
  });

  gsap.to(".dernier-projet-desktop", {
    scrollTrigger: {
      trigger: ".dernier-projet-desktop",
      start: "top 80%",
    },
    y: "-40px",
    duration: 0.3,
    delay: 0.3,
  });

  gsap.from(".dernier-projet-desktop", {
    scrollTrigger: {
      trigger: ".dernier-projet-desktop",
      start: "top 80%",
    },
    opacity: 0,
    duration: 0.3,
    delay: 0.3,
  });

  // ABOUT-ME

  gsap.to(".intro-me", {
    scrollTrigger: {
      trigger: ".intro-me",
      start: "top 70%",
    },
    y: "-20px",
    duration: 0.5,
    delay: 0.5,
  });

  gsap.from(".intro-me", {
    scrollTrigger: {
      trigger: ".intro-me",
      start: "top 70%",
    },
    opacity: 0,
    duration: 0.5,
    delay: 0.5,
  });

  gsap.to(".passionate", {
    scrollTrigger: {
      trigger: ".passionate",
      start: "top 80%",
    },
    y: "-40px",
    duration: 0.4,
    delay: 0.4,
  });

  gsap.from(".passionate", {
    scrollTrigger: {
      trigger: ".passionate",
      start: "top 80%",
    },
    opacity: 0,
    duration: 0.4,
    delay: 0.4,
  });

  gsap.to(".hobbies-desktop", {
    scrollTrigger: {
      trigger: ".hobbies-desktop",
      start: "top 80%",
    },
    y: "-40px",
    duration: 0.4,
    delay: 0.4,
  });

  gsap.from(".hobbies-desktop", {
    scrollTrigger: {
      trigger: ".hobbies-desktop",
      start: "top 80%",
    },
    opacity: 0,
    duration: 0.4,
    delay: 0.4,
  });
});

// PROJET IMG

gsap.to(".box-1", {
  scrollTrigger: {
    trigger: ".box-1",
    start: "top 80%",
  },
  y: "-15px",
  duration: 0.5,
  delay: 0.5,
});

gsap.from(".box-1", {
  scrollTrigger: {
    trigger: ".box-1",
    start: "top 80%",
  },
  opacity: 0,
  duration: 0.5,
  delay: 0.5,
});

gsap.to(".box-2", {
  scrollTrigger: {
    trigger: ".box-2",
    start: "top 80%",
  },
  y: "-15px",
  duration: 0.5,
  delay: 0.5,
});

gsap.from(".box-2", {
  scrollTrigger: {
    trigger: ".box-2",
    start: "top 80%",
  },
  opacity: 0,
  duration: 0.5,
  delay: 0.5,
});

gsap.to(".box-3", {
  scrollTrigger: {
    trigger: ".box-3",
    start: "top 80%",
  },
  y: "-15px",
  duration: 0.5,
  delay: 0.5,
});

gsap.from(".box-3", {
  scrollTrigger: {
    trigger: ".box-3",
    start: "top 80%",
  },
  opacity: 0,
  duration: 0.5,
  delay: 0.5,
});

gsap.to(".box-4", {
  scrollTrigger: {
    trigger: ".box-4",
    start: "top 80%",
  },
  y: "-15px",
  duration: 0.5,
  delay: 0.5,
});

gsap.from(".box-4", {
  scrollTrigger: {
    trigger: ".box-4",
    start: "top 80%",
  },
  opacity: 0,
  duration: 0.5,
  delay: 0.5,
});

gsap.to(".box-5", {
  scrollTrigger: {
    trigger: ".box-5",
    start: "top 80%",
  },
  y: "-15px",
  duration: 0.5,
  delay: 0.5,
});

gsap.from(".box-5", {
  scrollTrigger: {
    trigger: ".box-5",
    start: "top 80%",
  },
  opacity: 0,
  duration: 0.5,
  delay: 0.5,
});

gsap.to(".box-6", {
  scrollTrigger: {
    trigger: ".box-6",
    start: "top 80%",
  },
  y: "-15px",
  duration: 0.5,
  delay: 0.5,
});

gsap.from(".box-6", {
  scrollTrigger: {
    trigger: ".box-6",
    start: "top 80%",
  },
  opacity: 0,
  duration: 0.5,
  delay: 0.5,
});

gsap.to(".box-7", {
  scrollTrigger: {
    trigger: ".box-7",
    start: "top 80%",
  },
  y: "-15px",
  duration: 0.5,
  delay: 0.5,
});

gsap.from(".box-7", {
  scrollTrigger: {
    trigger: ".box-7",
    start: "top 80%",
  },
  opacity: 0,
  duration: 0.5,
  delay: 0.5,
});

gsap.to(".box-8", {
  scrollTrigger: {
    trigger: ".box-8",
    start: "top 80%",
  },
  y: "-15px",
  duration: 0.5,
  delay: 0.5,
});

gsap.from(".box-8", {
  scrollTrigger: {
    trigger: ".box-8",
    start: "top 80%",
  },
  opacity: 0,
  duration: 0.5,
  delay: 0.5,
});

gsap.to(".box-9", {
  scrollTrigger: {
    trigger: ".box-9",
    start: "top 80%",
  },
  y: "-15px",
  duration: 0.5,
  delay: 0.5,
});

gsap.from(".box-9", {
  scrollTrigger: {
    trigger: ".box-9",
    start: "top 80%",
  },
  opacity: 0,
  duration: 0.5,
  delay: 0.5,
});

gsap.to(".box-10", {
  scrollTrigger: {
    trigger: ".box-10",
    start: "top 80%",
  },
  y: "-15px",
  duration: 0.5,
  delay: 0.5,
});

gsap.from(".box-10", {
  scrollTrigger: {
    trigger: ".box-10",
    start: "top 80%",
  },
  opacity: 0,
  duration: 0.5,
  delay: 0.5,
});

gsap.to(".box-11", {
  scrollTrigger: {
    trigger: ".box-11",
    start: "top 80%",
  },
  y: "-15px",
  duration: 0.5,
  delay: 0.5,
});

gsap.from(".box-11", {
  scrollTrigger: {
    trigger: ".box-11",
    start: "top 80%",
  },
  opacity: 0,
  duration: 0.5,
  delay: 0.5,
});

gsap.to(".illustration-3", {
  rotation: 150,
  duration: 2,
  repeat: -1,
  yoyo: true,
  ease: "elastic.out",
});

gsap.to(".version-prenom img", {
  rotation: 150,
  duration: 2,
  repeat: -1,
  yoyo: true,
  ease: "elastic.out",
});

// MENU BURGER NAV

$(document).ready(function () {
  // Toggle menu
  $(".hamburger-container").click(function () {
    $("#menu").toggleClass("active");
  });
});

// LANGUE CHANGEMENT
